<template>
  <div>
    <Modal-Map
      v-if="showModal == true"
      @cancelModal="showModalOut"
      :type="infoModal"
    />
    <b-overlay :show="loadDimension" rounded="sm">
      <div class="container_site">
        <div class="ubication_dimension">
          <p class="ubication_dimension--home" @click="getRoute('Home')" style="color:#FFFFFF">
            Inicio
          </p>
          <img
            src="@/assets/img/arrow-breadcrumb.svg"
            class="ubication_dimension--breadcrumb"
            v-show="dimension.subcategory"
          />
          <p class="ubication_dimension--name_dimension" style="color:#FFFFFF" v-if="dimension.subcategory && dimension.subcategory == '2'">
            Dimensiones 
          </p>
          <p class="ubication_dimension--name_dimension" style="color:#FFFFFF" v-else>
            Secretarías
          </p>
          <img
            src="@/assets/img/arrow-breadcrumb.svg"
            class="ubication_dimension--breadcrumb"
          />
          <p class="ubication_dimension--name_dimension" style="color:#FFFFFF">
            {{ dimension.name_dimension }}
          </p>
        </div>
        <div class="container_dimension mb-5">
          <div class="container_dimension__menu--fixed">
            <div class="container_dimension__menu">
              <div class="container_dimension__menu--header">
                <div
                  class="container_dimension__menu--btnBack"
                  :style="{ 'background-color': dimension.color_dimension }"
                  @click="$router.push({ name: 'Indicators' })"
                  v-if="dimension.subcategory == '2'"
                >
                  <div class="btnBack__arrow">
                    <img src="@/assets/img/arrow-back.svg" />
                  </div>
                  <div class="btnBack__text">Regresar a Dimensiones</div>
                  
                </div>
                <div
                  v-else-if="dimension.subcategory == null"
                  class="container_dimension__menu--btnBack"
                  :style="{ 'background-color': dimension.color_dimension }"
                  @click="$router.push({ name: 'SubCategoryProduct' })"
                >
                  <div class="btnBack__arrow">
                    <img src="@/assets/img/arrow-back.svg" />
                  </div>
                  <div class="btnBack__text">Regresar a Dimensiones</div>
                </div>
                <div
                  v-else-if="dimension.subcategory == 1"
                  class="container_dimension__menu--btnBack"
                  :style="{ 'background-color': dimension.color_dimension }"
                  @click="$router.push({ name: 'SubCategoryProduct' })"
                >
                  <div class="btnBack__arrow">
                    <img src="@/assets/img/arrow-back.svg" />
                  </div>
                  <div class="btnBack__text">Regresar a Secretarías </div>
                </div>

                <div class="container_dimension__menu--dimension">
                   {{ dimension.subcategory ? 'SUBCATEGORÍA' : 'DIMENSIÓN' }}
                </div>
                <div class="container_dimension__menu--title">
                  {{ dimension.name_dimension }}
                </div>
              </div>
              <div class="container_dimension__menu--list">

                <div
                
                  v-for="(value, key) in segments"
                  :key="key"
                >
                <div  class="container_dimension__item"
                  
                >
                <a 
                  :href="'#Category-' + value.id"
                  class="item_segment"
                  
                >
                  <div
                    class="container_dimension__item--link"
                    v-b-toggle="'accordion-' + value.id"
                  >
                    <div class="container_dimension__item--arrow">
                      <img
                        src="@/assets/img/arrow-close.svg"
                        style="width: 13px; important!"
                        v-if="!collapseSegments[key].toggle"
                      />
                      <img
                        src="@/assets/img/arrow-gray-right.svg"
                        v-else
                        style="transform: rotate(90deg); width: 13px; important!"
                      />
                    </div>
                    <div
                      class="container_dimension__item--text"
                      :style="{
                        color: collapseSegments[key].toggle
                          ? '#383838'
                          : '#707070',
                      }"
                    >
                      {{ value.name }}
                    </div>
                  </div>
                </a>
                  <b-collapse
                    :id="'accordion-' + value.id"
                    accordion="my-accordion"
                    class="container_dimension__item--collapse"
                  >
                    <a
                      :href="'#Category-' + value.id"
                      class="item_segment"
                      :style="hoverCategory"
                      v-for="(value, key) in value.tags.categories"
                      :key="key"
                      >{{ value.name }}</a
                    >
                  </b-collapse>
                </div>

                </div>
              </div>
            </div>
          </div>

          <div class="container_dimension__content">
            <div class="container_dimension__content--card">
              <div
                class="container_dimension__content--imgDimension"
                :style="{
                  'background-image': `url(${dimension.img_dimension ? dimension.img_dimension.url : ''})`,
                  'background-size': 'cover',
                  'background-position': 'center',
                }"
              ></div>
              <div
                class="container_dimension__content--description"
                :style="{ 'background-color': dimension.color_dimension }"
              >
                <div class="container_dimension__content--title">
                  {{ dimension.name_dimension }}
                </div>
                <div class="container_dimension__content--text">
                  {{ dimension.text_dimension }} 
                </div>
              </div>
            </div>
            <div
              class="container_dimension__content--segments"
              v-if="id == 1 || id == 2 || id == 3 || id == 4 || id == 5"
            >
              <div
                class="container_dimension__content--title_segment"
                :style="{ 'background-color': dimension.color_dimension }"
              >
                Líneas estratégicas
              </div>

              <dimension-special :dimension="id" />
            </div>

           <div class="container_dimension__content--segments"
              v-for="(Segment, keySegment) in segments"
              :key="keySegment">

            <div
              
            >
            
              <div
                class="container_dimension__content--title_segment scrollspy" 
                :style="{ 'background-color': Segment.color ? Segment.color : dimension.color_dimension }"
                :id="'Category-' + Segment.id"
                
              >
                {{ Segment.name }}
              </div>
              <div v-if="Segment.description"
                class="container_dimension__content--description_segment"
                v-html="Segment.description"
                
              >
              </div>

              <div v-else
                class="container_dimension__content--description_segment"
              >

               <div class="container_segments_table"  :class="{'expanded-block': Segment.open}">
  

                <table v-if="Segment.infoTable">
    
                  <tr>
                    <th>Indicador</th>
                    <th>Desagregación geográfica</th>
                    <th>Unidad de medida</th>
                    <th>2018</th>
                    <th>2019</th>
                    <th>2020</th>
                    <th>2021</th>
                    <!-- <th v-if="Segment.id == 208">2022</th> -->
                    <th>2022</th>
                    <th>2023</th>
                    <th>Info</th>
                  </tr>
                <tr v-for="(item, index) in Segment.infoTable" >
                  <td v-if="Segment.id == 208 && index == 1">
                    {{ item[0]}}
                    <!-- <div>
                      <a href="/pdf/Rutas_turisticas_2022.pdf" target="_blank">
                      Ver mas..<img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                    </div> -->

                    <!-- <button 
                        type="button" 
                      
                        @click="downloadRoute('Ruta Miradores.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Miradores</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important;">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Sendero Ecologico.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Sendero ecológico</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta vereda El Carmen.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
       
                      >
                        <span >Vereda el carmen</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Gastronomica.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                     
                      >
                        <span >Gastronómica</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>


                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta los Tabacales.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Tabacales</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>


                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Religiosa.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Religiosa</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Cafetera.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Cafetera</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Ecologica.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Ecológica</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>

                    <button 
                        type="button" 
                        @click="downloadRoute('Ruta Murales.pdf')"
                        :style="{'background-color':dimension.color_dimension }"
                        class="button_expand button_routes"
                      >
                        <span >Murales</span>
                        <span class="button_routes__icon" style="color: #FFFFF !important">
                        <ArrowDownIcon :color="dimension.color_dimension"/>
                      </span>
                    </button>                        -->
                  
                    
                  </td>
                  <td v-else>{{ item[0]}}</td>
                  <td>{{ item[3]}}</td>
                  <td>{{ item[4]}}</td>


                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{ isNaN(item[5]) ? item[5] : Number(item[5]).toLocaleString() }}</td>
                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[6])  ? item[6] : Number(item[6]).toLocaleString() }}</td>
                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[7])  ? item[7] : Number(item[7]).toLocaleString() }}</td>
                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[8])  ? item[8] : Number(item[8]).toLocaleString() }}</td>
                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[9])  ? item[9] : Number(item[9]).toLocaleString() }}</td>
                  <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[9])  ? item[9] : Number(item[10]).toLocaleString() }}</td>

                  <td v-if="item[4] != 'Pesos' ">{{ item[5]}}</td>
                  <td v-if="item[4] != 'Pesos' ">{{ item[6]}}</td>
                  <td v-if="item[4] != 'Pesos' ">{{ item[7]}}</td>
                  <td v-if="item[4] != 'Pesos' ">{{ item[8]}}</td>
                  <td v-if="item[4] != 'Pesos' ">{{ item[9]}}</td>
                  <td v-if="item[4] != 'Pesos' ">{{ item[10]}}</td>

                  <!-- <td v-if="Segment.id == 208">{{ item[9]}}</td> -->
                  <!-- <td>{{ item[9]}}</td> -->
                  <td>
                    <button 
                    :style="{'background-color':dimension.color_dimension }" 
                    class="boton notranslate"
                    @click="goToGraphTableModal(item,Segment.name,index,Segment.id,false)"
                    >i</button>

                    <button v-if="(Segment.id == 203 && index == 5) || (Segment.id == 206 && index == 15) || (Segment.id == 207 && index == 4) || (Segment.id == 200 && index == 24)"
                    :style="{'background-color':dimension.color_dimension }" 
                    class="boton notranslate"
                    @click="goToGraphTableModal(item,Segment.name,index,Segment.id,true)"
                    >A</button>
                    
                  </td>
                </tr>


                </table>
              
                </div>

                

                  <button 
                    type="button" 
                    v-if="Segment.infoTable.length > 2" 
                    :style="{'background-color':dimension.color_dimension }"
                    class="button_expand fixed_button button_expand_table"
                    @click="Segment.open = !Segment.open"
                  >
                    <span v-if="!Segment.open">Expandir tabla</span>
                    <span v-if="Segment.open">Reducir tabla</span>
                    <span class="button_expand_table__icon" style="color: #FFFFF !important">
                    <ArrowDownIcon :color="dimension.color_dimension" v-if="!Segment.open"/>
                    <ArrowUpIcon :color="dimension.color_dimension" v-if="Segment.open" />
                  </span>
                  </button>

              </div>
<!-- ########################################################################### -->
              <div
                class="container_dimension__content--info_item"
                v-for="(Category, keyCategory) in Segment.tags.categories"
                :key="keyCategory"
              >
     
                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-if="Category.id == 291"
                >
                  <div>
                    {{ Category.name }}
                    <a href="/pdf/PP ENVEJECIMIENTO HUMANO Y VEJEZ.pdf" download>
                      <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                  </div>
                </div>

                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-else-if="Category.id == 289"
                >
                  <div>
                    {{ Category.name }}
                    <a href="/pdf/PP JUVENTUD.pdf" download>
                      <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                  </div>
                </div>

                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-else-if="Category.id == 290"
                >
                  <div>
                    {{ Category.name }}
                    <a href="/pdf/PP EQUIDAD DE GÉNERO Y IGUALDAD DE OPORTUNIDAD PARA LAS MUJERES.pdf" download>
                      <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                  </div>
                </div>

                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-else-if="Category.id == 287"
                >
                  <div>
                    {{ Category.name }}
                    <a href="/pdf/PP PRIMERA INFANCIA Anexo Acuerdo No.012.pdf" download>
                      <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                  </div>
                </div>

                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-else-if="Category.id == 288"
                >
                  <div>
                    {{ Category.name }}
                    <a href="/pdf/PP ACCESO A VIVIENDA.pdf" download>
                      <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                    </a>
                  </div>
                </div>

                <div
                  class="container_dimension__content--info_title scrollspy"
                  :id="'Category-' + Category.id"
                  v-else
                >
 
                  {{ Category.name }}
                </div>
                <div class="container_dimension__content--info_text" v-html="Category.description">
                </div>
                <div class="container_dimension__content--content_graph">
                  <div
                    class="container_dimension__content--info_graph"
                    v-for="(Graph, keyGraph) in Category.graphs.tables"
                    :key="keyGraph"
                    :style="{ width: Graph.type == 3 ? '47%' : '100%' }"
                  >

                    <div v-if="Graph.type == 12" class="container_segments_table"  :class="{'expanded-block': Category.open}">
                      
                      <table v-if="Category.infoTable">
                        
                        <tr>
                          <th>INDICADOR EN EL PDM 2020-2023</th>
                          <th>UNIDAD DE MEDIDA</th>
                          <th>META 2020</th>
                          <th>EJECUTADO 2020</th>
                          <th>META 2021</th>
                          <th>EJECUTADO 2021</th>
                          <th>META 2022</th>
                          <th>AVANCE 2022</th>
                          <th>META 2023</th>
                       </tr>
                         <tr v-for="item in Category.infoTable" >
                           <td>{{ item[3]}}</td>
                           <td>{{ item[4]}}</td>
                           <td>{{ item[5]}}</td>
                           <td>{{ item[6]}}</td>
                           <td>{{ item[7]}}</td>
                           <td>{{ item[8]}}</td>
                           <td>{{ item[9]}}</td>
                           <td>{{ item[10]}}</td>
                           <td>{{ item[11]}}</td>
                         </tr>
                       </table>

                    </div>

                    <div v-if="Graph.type == 13" class="container_segments_table"  :class="{'expanded-block': Category.open}">
                      
                      <table v-if="Category.infoTable">
                  
                        <tr>
                          <th>ACCIONES DE LA POLÍTICA - Acuerdo 012 de 2019</th>
                          <!-- <th>PRODUCTO EN EL PDM 2020-2023</th> -->
                          <th>INDICADOR EN EL PDM 2020-2023</th>
                          <th>UNIDAD DE MEDIDA</th>
                          <th>META 2020</th>
                          <th>EJECUTADO 2020</th>
                          <th>META 2021</th>
                          <th>EJECUTADO 2021</th>
                          <th>INFO</th>
                       </tr>
                         <tr v-for="item in Category.infoTable" >
                           <td>{{ item[0]}}</td>
                           <!-- <td>{{ item[1]}}</td> -->
                           <td>{{ item[2]}}</td>
                           <td>{{ item[3]}}</td>
                           <td>{{ item[4]}}</td>
                           <td>{{ item[5]}}</td>
                           <td>{{ item[6]}}</td>
                           <td>{{ item[7]}}</td>
 
                           <td>
                            <button 
                            :style="{'background-color':dimension.color_dimension }" 
                            class="boton notranslate"
                            @click="goToGraphTableModalCategory(item,Segment.name)"
                            >i</button>
                          </td>
                         </tr>
                       </table>

                    </div>

                   <button 
                    type="button" 
                    v-if="Category.infoTable.length > 2" 
                    :style="{'background-color':dimension.color_dimension }"
                    class="button_expand fixed_button button_expand_table"
                    @click="Category.open = !Category.open"
                  >
                    <span v-if="!Category.open">Expandir tabla</span>
                    <span v-if="Category.open">Reducir tabla</span>
                    <span class="button_expand_table__icon" style="color: #FFFFF !important">
                    <ArrowDownIcon :color="dimension.color_dimension" v-if="!Category.open"/>
                    <ArrowUpIcon :color="dimension.color_dimension" v-if="Category.open" />
                  </span>
                  </button>


                    <div
                      v-if="Graph.type != 10 && Graph.type != 12 && Graph.type != 13"
                      class="container_dimension__content--title_graph"
                    >
                      {{ Graph.name }}
                    </div>
                    <div
                      class="container_dimension__content--description_graph"
                    >
                      {{ Graph.description }}
                    </div>
                    <Graphs :type="Graph" />
                    <div
                      v-if="Graph.type != 10 && Graph.type != 12 && Graph.type != 13"
                      class="
                        container_dimension__content--description_graph--img
                      "
                    >
                      <img
                        class="full__screen"
                        :src="require('../../assets/img/fullscreen.png')"
                        alt=""
                        @click="goToModal(Graph)"
                      />
                    </div>
                  </div>
                </div>
                <div class="container_dimension__content--content_graph">
                  <div
                    class="container_dimension__content--info_graph"
                    v-for="(Image, keyImage) in Category.images"
                    :key="keyImage"
                  >
                    <div
                      class="container_dimension__content--title_graph"
                      :style="{ 'padding-left': '15px' }"
                    >
                      {{ Image.title }}
                    </div>
                    <div
                      class="container_dimension__content--description_graph"
                    >
                      <img
                        :src="Image.image.url"
                        alt=""
                        :style="{ width: '100%' }"
                      />
                    </div>
                    <div
                      class="container_dimension__content--description_graph"
                      :style="{ 'padding-left': '30px' }"
                    >
                      {{ Image.description }}
                    </div>
                  </div>
                </div>
                <div class="container_dimension__content--content_graph">
                  <div
                    class="container_dimension__content--info_graph"
                    v-for="(Video, keyVideo) in Category.videos"
                    :key="keyVideo"
                  >
                    <div
                      class="container_dimension__content--title_graph"
                      :style="{ 'padding-left': '15px' }"
                    >
                      {{ Video.title }}
                    </div>
                    <div
                      class="container_dimension__content--description_graph"
                    >
                      <video
                        :src="Video.video.url"
                        :style="{ width: '100%' }"
                        controls
                      ></video>
                    </div>
                    <div
                      class="container_dimension__content--description_graph"
                      :style="{ 'padding-left': '30px' }"
                    >
                      {{ Video.description }}
                    </div>
                  </div>
                </div>
                <div class="container__pdf">
                  <div
                    class="container__pdf--info_pdf"
                    v-for="(Pdf, keyPdf) in Category.pdfs"
                    :key="keyPdf"
                    @click="goToPdf(Pdf.pdf.url)"
                  >
                    <div class="container__pdf--title_pdf">
                      {{ Pdf.title }}
                    </div>
                    <div class="container__pdf--description_pdf">
                      {{ Pdf.description }}
                    </div>
                    <div class="container__pdf--img_pdf">
                      <img
                        :src="require('../../assets/img/icono-pdf.svg')"
                        alt=""
                      />
                    </div>
                    <div class="container__pdf--button_pdf">
                      <button>Ver documento</button>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(MapGraph, keyMap) in Category.graphs.maps"
                  :key="keyMap"
                >
                  <Maps :type="MapGraph" />
                  <!-- <img class="full__screen" :src="require('../../assets/img/fullscreen.png')" alt="" @click="goToModal(MapGraph)"> -->
                </div>
                <div class="container_dimension__content--content_products">
                    <div class="container_dimension__content--content_products--item" 
                         v-for="(Product, keyProduct) in Category.products" :key="keyProduct" @click="goToProductModal(Product,Category.products)">
                        
                        <div class="container_dimension__content--content_products--item__title">
                            <p>{{Product.name}}</p>
                        </div>
                        <div class="container_dimension__content--content_products--item__button" :style="{'background-color': Segment.color ? Segment.color : dimension.color_dimension}">
                            <button>Abrir producto<img :src="require('../../assets/img/icons-modal-products/abrir-producto.png')" alt=""></button>
                        </div>
                    </div>
                </div>
                
              </div>
            </div>
          </div>
            
          </div>
        </div>


        <div class="container_dimension_mobile mb-3" :style="{'border-top': '10px solid '+dimension.color_dimension}">
                    <div class="container_dimension_mobile--title_dimension" v-if="!openMenuDimension">
                        <div style="width: 98%">{{dimension.name_dimension}}</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <MenuIcon />
                        </a>
                    </div>

                    <div class="container_dimension_mobile--title_dimension" v-if="openMenuDimension">
                        <div style="width: 100%" v-if="dimension.subcategory">Selecciona una secretaría</div>
                        <div style="width: 100%" v-else>Selecciona una dimensión</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <ArrowUpIcon width="18.828" height="10.414" color="#000000" />
                        </a>
                    </div>

                    <DimensionMovileList v-if="openMenuDimension" :subcategory="dimension.subcategory" />
                    
        </div>

        <div
          class="container_dimension_mobile mb-5"
          :style="{ 'border-top': '10px solid ' + dimension.color_dimension }"
        >
          <div
            class="container_dimension_mobile--header_dimension"
            :style="{
              'background-image': `url(${dimension.img_dimension ? dimension.img_dimension.url : ''})`,
              'background-size': 'cover',
              'background-position': `${dimension.subcategory ? 'center' : 'top center'}`,
            }"
          ></div>
          <div class="container_dimension_mobile--title_dimension">
            
          </div>

          <div class="container_dimension_mobile__card_segments">
            

            <div
              class="container_dimension_mobile__card_segment"
              v-for="(Segment, keySegment) in segments"
              :key="keySegment"
              :style="{
                'border-top': '10px solid ' + dimension.color_dimension,
              }"
              @click="$router.push({ name: 'Segments', params: { id: id } })"
            >
              <div
                class="container_dimension_mobile__card_segment--title_segment"
              >
                {{ Segment.name }}
              </div>
              <!-- aqui -->
              <div
                class="
                  container_dimension_mobile__card_segment--description_segment text_segment
                "
                v-html="Segment.description"
              ></div>
              
              <div
                class="container_dimension_mobile__card_segment--btn_see_more"
                :style="{ 'background-color': dimension.color_dimension }"
              >
                <p>Ver más</p>
                <img src="@/assets/img/arrow-btn-vermas.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="container_btn_actions mb-5">
          <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
            <img src="@/assets/img/icono-regresar.svg" alt="" />
            <p>Regresar</p>
          </div>
          <div
            class="container_btn_actions__btn_home"
            @click="$router.push({ name: 'Home' })"
          >
            <img src="@/assets/img/icono-inicio.svg" alt="" />
            <p>Inicio</p>
          </div>
        </div>
      </div>
    </b-overlay>
    <Product-Modal v-if="showProduct==true"
      :product="product"
      :modalCategory="modalCategory"
      @cancelProduct="showProductOut"
      @otherProduct="goToOtherProduct"
    />
    <Graph-Table-Modal v-if="showGraph==true"
      :product="product"
      :color="dimension.color_dimension"
      :segmentName="segmentName"
      :indexGraphTableModal="indexGraphTableModal"
      :segmentId="segmentId"
      :showExcel="showExcel"
      @cancelProduct="showProductOut"
    />
    <Graph-Table-Modal-Category v-if="showGraphCategory==true"
      :product="product"
      :color="dimension.color_dimension"
      :segmentName="segmentName"
      @cancelProduct="showProductOut"
    />
  </div>
</template>
<script>
import $ from "jquery";
import DimensionSpecial from "../shared/DimensionSpecial.vue";
import DimensionSpecialMobil from "../shared/DimensionSpecialMobil.vue";
var scroll_segments = [];

export default {
  components: { DimensionSpecial, DimensionSpecialMobil },
 
  data() {
    return {
      infoModal: {},
      id: this.$route.params.id,
      dimension: [],
      segments: [],
      collapseSegments: [],
      loadDimension: true,
      showModal: false,
      product:[],
      modalCategory:[],
      showProduct:false,
      showGraph:false,
      showGraphCategory:false,
      openMenuDimension: false,
      image: '',
      infoTable: '',
      segmentName: '',
      indexGraphTableModal:'',
      segmentId:'',
      showExcel:false
      
    };
  },
  methods: {

    downloadRoute(file){
      // window.download("/pdf/Ruta Miradores.pdf");
      var file_path = '/pdf/'+file;
      var a = document.createElement('A');
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    goToOtherProduct(a,b){
      this.showProduct=false
      this.goToProductModal(a,b)
      //console.log('Retornando algo',a,b)
    },
    goToProductModal(product,category){
      this.product=product
      this.modalCategory = category
      this.showProduct=true
    },
    goToGraphTableModal(product,name,index,id,excel){

      this.product=product
      this.indexGraphTableModal=index
      this.segmentId=id
      this.showExcel=excel
      this.segmentName = name
      // this.modalCategory = category
      this.showGraph=true
    },
    goToGraphTableModalCategory(product,name){
     
      this.product=product
      this.segmentName = name
      // this.modalCategory = category
      this.showGraphCategory=true
    },
    showProductOut(value) {
      this.showProduct = value;
      this.showGraph = value
      this.showGraphCategory = value
    },
    showModalOut(value) {
      this.showModal = value;
    },
    goToPdf(data) {
      window.open(data, "_blank");
    },
    goToModal(info) {
      this.infoModal = info;
      //console.log(this.infoModal);
      this.redirect();
    },
    redirect() {
      this.showModal = true;
    },
    getDimension() {
      this.$http
        .get("dimensions/" + this.id)
        .then((response) => {
          this.getSegments();
          ////console.log('Dimensión consultada: ', response.body)
          this.dimension = response.body;
          console.log('dimension',this.dimension)

        })
        .catch((error) => {
          ////console.log('Error al consultar la dimensión: ', error)
        });
    },



    getSegments() {


      this.$http
        .get("architecture_tags/dimension/" + this.id)
        .then((response) => {
          console.log('Segmentos consultados: ', response.body)

         this.segments = response.body.Segments;
         console.log('this segments', this.segments)
          this.loadDimension = false;

          response.body.Segments.forEach((element) => {
            this.collapseSegments.push({
              name: "accordion-" + element.id,
              toggle: false,
            });

            element.tags.categories.forEach((Category) => {
              scroll_segments.push("Category" + Category.id);
            });



          });


          // response.body.Segments.forEach((segment) => {

          //   var image = segment.description.split('<img src=\"data:image/jpeg;base64,')
          //   var image2 = image[1].split('">')
          //   var image3 = image2[0]

          //     this.segments.push({
          //       color: segment.color,
          //       description: segment.description,
          //       icon: segment.icon,
          //       id: segment.id,
          //       name: segment.name,
          //       tags: segment.tags,
          //       image: 'hola'
          //     });
          //   });

          // console.log('segments con imagenes', this.segments)
          
          if (this.$route.hash !== '') {
            setTimeout( () => {
              console.log('jvargas',$('a[href="'+this.$route.hash+'"]'))
              $('a[href="'+this.$route.hash+'"]').click()              
            }, 800)
          }

                              
           response.body.Segments.forEach((element) => {
                 
                element.tags.categories.forEach((category) => {

                    if( category.segment == false){

                      if(category.graphs.tables.length > 0){

                          var formater = JSON.parse(category.graphs.tables[0].data)

                          category.infoTable = formater

                          // category.infoTable.shift()

                          console.log('category', category)

                        }

                      
                      }
                  });

            

          });


          
           this.segments.forEach((element) => {

             if( element.segment == true){

               if(element.graphsTable.length > 0){
                  
                  var formater = JSON.parse(element.graphsTable[0].data)

                  element.infoTable = formater

                  // element.infoTable.shift()

                  console.log('element', element)
               }

            }

          });




        })
        .catch((error) => {
          ////console.log('Error al consultar los segmentos: ', error)
        });
    },
    getRoute(name) {
      if (this.$route.name != name) {
        this.$router.push({ name: name });
      }
    },

    // prueba(){
    //   var data = 'Category-'+11
    //   console.log('la data', data)
    //   const element = document.getElementById(data);
    //   element.scrollIntoView();
    // }
  },
  created() {
    this.getDimension();

    setTimeout(()=>{
      
      console.log('la category es', this.$route.query.Category)

      var divId = 'Category-'+this.$route.query.Category

      const element = document.getElementById(divId);
      // element.scrollIntoView();
      
      this.$smoothScroll({
        scrollTo: element
      })

    },1000);

  },

  mounted(){

    
  },
  computed: {
    hoverCategory() {
      return {
        "--hover-color": this.dimension.color_dimension,
      };
    },
  },
  mounted() {

    console.log('this route', this.$route)

    var vm = this;
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      this.collapseSegments.forEach((element, index) => {
        if (element.name == collapseId) {
          this.collapseSegments[index].toggle = isJustShown;
        }
      });
    });

    $(this).ready(function () {
      // if (vm.$route.query.Category != undefined) {
      //     //console.log('Scroll: ',$('#Category-'+vm.$route.query.Category))
      // }
      // //console.log('Category: ',$("body, html").prevObject[0])
      // //console.log('Category: ',$("body, html").prevObject[0].all)
      var element = $("body, html").prevObject[0].all;

      //console.log("Elements", element);

      $(this).on("click", ".item_segment", function () {
        var target = $(this).attr("href");
        $("body, html").animate(
          {
            scrollTop: $(target).offset().top,
          },
          300
        );
        return false;
      });
    });

    $(window).bind("scroll", function () {
      var currentTop = $(window).scrollTop();
      var elems = $(".scrollspy");
      elems.each(function (index) {
        var elemTop = $(this).offset().top;
        var elemBottom = elemTop + $(this).height();
        if (currentTop >= elemTop && currentTop <= elemBottom) {
          var id = $(this).attr("id");
          var navElem = $('a[href="#' + id + '"]');
          $(".item_segment").removeClass("active_segment");
          navElem.addClass("active_segment");
          var name = navElem.parent().attr("id");

          $(".collapse").hide();
          $("#" + name).css("display", "flex");

          for (let index = 0; index < vm.collapseSegments.length; index++) {
            if (vm.collapseSegments[index].name == name) {
              vm.collapseSegments[index].toggle = true;
            } else {
              vm.collapseSegments[index].toggle = false;
            }
          }
        }
      });
    });
  },
};
</script>
<style scoped lang="scss">

.button_expand_table {
  width: 40% !important;
  margin-left: 30% !important;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
}

.button_routes{
  width: 45% !important;
  // margin-left: 30% !important;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td{
  
  text-align: left;
  padding: 8px;
  font-size: 14px;
  height: 100px;
  text-align: center;
  border-right: 1px solid #ccc;
}

th{
  background-color: #383838;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  height: 60px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #F4F4F4;
  
}

.boton{
  width:30px;
  height:30px;
  margin: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size:19px;
  color: #FFFFFF;

}
.boton:hover{
  opacity: 0.50;
  -moz-opacity: .50;
  filter:alpha (opacity=50);
}
.boton a{
  color:#fff;
  text-decoration:none;
  padding:5px 5px 5px 0;
}


.full__screen {
  height: 20px;
}
.container_dimension__content--description_graph--img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.container_dimension__content--content_products{
    margin-top: 43px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.container_dimension__content--content_products--item{
    height: auto;
    width: 260px;
    border-radius:5px;
    border: 1px solid #C6C6C6;
    margin:5px;
} 
.container_dimension__content--content_products--item__title p{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin: 15px;
}
.container_dimension__content--content_products--item__button{
    height: 26px;
    width: 230px;
    border-radius: 5px;
    margin:0 15px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.container_dimension__content--content_products--item__button button{
    background: none;
    width: 100%;
    border: none;
    font-size: 12px;
    text-align: left;
    color: white;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.text_segment{
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
</style>